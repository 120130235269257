import React from 'react'
import { TablePaginationConfig } from 'antd'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType } from '../../types/enums'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'
import moment from 'moment'
import Table from '../tools/Table'

interface IOwnStoreTableProps {
  list: IOwnStoreItem[]
  total: number
  pageInfo: { page: number; size: number }
  onPageChange: (props: { size: number; page: number }) => void
  onClickByItem: (data: IOwnStoreItem) => void
}

const OwnStoreTable = ({
  list,
  total,
  pageInfo,
  onPageChange,
  onClickByItem,
  ...props
}: IOwnStoreTableProps) => {
  const { configMap, areaList, employeeList } = useStoreViewContext()

  const columns = [
    {
      title: 'CN Code',
      dataIndex: 'Store_Code',
      width: 100,
      fixed: true,
      render: (text, item) => (
        <a
          onClick={(e) => {
            onClickByItem(item)
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'CN Name',
      dataIndex: 'Name_CN',
      fixed: true,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      width: 120,
      render: (id) => (
        <span>
          {
            configMap
              .get(EnumConfigType.Store_Status)
              ?.find((item) => item.Id == id)?.Value
          }
        </span>
      ),
    },
    {
      title: 'RD',
      dataIndex: 'RM',
      width: 80,
      render: (id) => (
        <span>
          {employeeList?.find((item) => item.Employee_No == id)?.Name_CN}
        </span>
      ),
    },
    {
      title: 'CM',
      dataIndex: 'CM',
      width: 80,
      render: (id) => (
        <span>
          {employeeList?.find((item) => item.Employee_No == id)?.Name_CN}
        </span>
      ),
    },
    {
      title: 'Planing Open Date',
      dataIndex: 'Planning_Open_Date',
      render: (time) => (
        <span>{time ? moment(time * 1000).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'Final Opening Date',
      dataIndex: 'Final_Opening_Date',
      render: (time) => (
        <span>{time ? moment(time * 1000).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'Closed Date',
      dataIndex: 'Closed_Date',
      width: 120,
      render: (time) => (
        <span>{time ? moment(time * 1000).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'CityChinese',
      dataIndex: 'City',
      render: (id) => (
        <span>{areaList?.find((item) => item.Id == id)?.Name_CN}</span>
      ),
    },
    {
      title: 'Store Type',
      dataIndex: 'Store_Type',
      width: 120,
      render: (id) => (
        <span>
          {
            configMap
              .get(EnumConfigType.Store_Type)
              ?.find((item) => item.Id === id).Value
          }
        </span>
      ),
    },
  ]
  const data = list.map((item) => ({ ...item, key: item.OR_No }))

  const onChangeByTable = (pagination: TablePaginationConfig) => {
    const { pageSize: size, current: page } = pagination
    if (pageInfo.size != size || pageInfo.page != page) {
      onPageChange({ size, page })
    }
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChangeByTable}
        pagination={{
          total,
          showTotal:(total) => `Total ${total} items`,
          current: pageInfo.page,
          pageSize: pageInfo.size,
          showSizeChanger: true,
        }}
        scroll={{ x: 1200 }}
      />
    </>
  )
}

export default OwnStoreTable
