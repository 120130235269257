import { RadioChangeEvent } from 'antd'
import { Moment } from 'moment'
import React from 'react'
import {
  EnumConfigType,
  EnumFormFieldType,
  EnumRolePostion,
} from '../../types/enums'
import { IFormField } from '../../types/interface'

export const filterFields: IFormField[] = [
  {
    name: 'Store_Code',
    label: 'CN Code',
    type: EnumFormFieldType.AutoComplete,
    col: 12,
    optionsName: 'storeCodeList',
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
  },
  {
    name: 'Name_CN',
    label: 'CN Name',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Final_Opening_Date',
    label: 'Final Opening Date',
    col: 12,
    type: EnumFormFieldType.DateRangePicker,
  },
  {
    name: 'Closed_Date',
    label: 'Closed Date',
    type: EnumFormFieldType.DateRangePicker,
    col: 12,
  },
  {
    name: 'Status',
    label: 'Status',
    type: EnumFormFieldType.Select,
    optionsName: 'storeStatus',
    col: 12,
  },
  {
    name: 'CM',
    label: 'CM',
    type: EnumFormFieldType.Select,
    optionsName: 'CMList',
    col: 12,
  },
  {
    name: 'Region',
    label: 'Region',
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 12,
  },
]

export const StoreInfoFields: IFormField[] = [
  {
    name: 'Store_Code',
    label: 'CN Code',
    require: true,
    type: EnumFormFieldType.Input,
    col: 8,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
    getValueFromEvent: (event) => {
      return event.target.value.replace(/[^0-9a-zA-Z]+/g, '')
    },
  },
  {
    name: 'OR_No',
    label: 'OR',
    require: true,
    type: EnumFormFieldType.Input,
    col: 8,
  },
  {
    name: 'Status',
    label: '状态',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'storeStatus',
    col: 8,
  },
  {
    name: 'Name_EN',
    label: 'English Name ',
    require: true,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Name_CN',
    label: 'CN Name ',
    require: true,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Country',
    label: 'Country',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'countryList',
    extra: {
      disabled: true,
    },
  },
  {
    name: 'ProvinceCityDistrict',
    label: 'Province/City/District',
    require: true,
    type: EnumFormFieldType.Cascader,
    optionsName: 'areaOptions',
    onChange: ({ value, optionsMap, form }) => {
      const cityId = value?.[1] || null
      const levelId =
        optionsMap.cityTierList?.find((item) => item.City_Id == cityId)?.Tier ??
        null
      form.setFields([{ name: 'City_Level', value: levelId }])
    },
    extra: {
      showSearch: {
        filter: (inputValue, path) =>
          path.some(
            (option) =>
              option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ),
      },
    },
  },
  {
    name: 'Address',
    label: 'Address',
    require: true,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Post_Code',
    label: 'POST Code',
    require: true,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'City_Level',
    label: 'City Level',
    type: EnumFormFieldType.Select,
    optionsName: 'cityLevelList',
    extra: {
      disabled: true,
    },
  },
  {
    name: 'City_Rank',
    label: 'City Rank',
    type: EnumFormFieldType.Input,
    getValueFromEvent: (event, form) => {
      return event.target.value.replace(/[^0-9a-zA-Z]+/g, '')
    },
  },
  {
    name: 'Business_Group',
    label: 'Business Group',
    require: true,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Shopping_Mall_Tell',
    label: 'Shopping Mall Tell',
    type: EnumFormFieldType.Input,
    placeholder: '区号+号码',
  },
  {
    name: 'Opening_Time',
    label: 'Opening Time',
    require: true,
    type: EnumFormFieldType.Input,
    placeholder: '例如:周日至周四 9:30-21:30 周五至周六 9:30-22:00',
  },
  {
    name: 'Store_Type',
    label: 'Store Type',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'storeType',
  },
  {
    name: 'Floor',
    label: 'Floor',
    require: true,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Size',
    label: 'Size',
    require: true,
    type: EnumFormFieldType.Input,
    rules: [
      {
        pattern: new RegExp(/^(([0-9]+)(([.][0-9]*)?))$/),
        message: `请输入正确的面积`,
        validator(_, value) {
          if (!value) return Promise.resolve('')
          if (value > 0) {
            return Promise.resolve()
          }
          return Promise.reject(new Error('请输入正确的面积'))
        },
      },
    ],
  },
  {
    name: 'Zstime_Store',
    label: "Zstime's Store",
    require: true,
    type: EnumFormFieldType.Radio,
    extra: {
      options: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    },
  },
  {
    name: 'OR_PR',
    label: 'OR/PR',
    require: true,
    type: EnumFormFieldType.Radio,
    extra: {
      options: [
        { label: 'OR', value: 1 },
        { label: 'TR', value: 0 },
      ],
    },
  },
  {
    name: 'Project_Start_Date',
    label: 'Project Start Date',
    require: true,
    type: EnumFormFieldType.DatePicker,
    onChange: ({ form }) => {
      if (form.getFieldValue('Planning_Open_Date')) {
        form.validateFields(['Planning_Open_Date'])
      }
      if (form.getFieldValue('Final_Opening_Date')) {
        form.validateFields(['Final_Opening_Date'])
      }
    },
  },
  {
    name: 'Planning_Open_Date',
    label: 'Planning Open Date',
    col: 12,
    require: true,
    type: EnumFormFieldType.DatePicker,
    onChange: ({ form, value }) => {
      form.setFields([{ name: 'Final_Opening_Date', value }])
      form.validateFields(['Final_Opening_Date'])
    },
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) return Promise.resolve('')
          const limitTime = (getFieldValue('Project_Start_Date') as Moment)
          ?.startOf('day')
          .unix()
          const currentTime = value?.startOf('day').unix()
          if (limitTime && limitTime <= currentTime) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error('Planning Open Date必须晚于Project Start Date。')
          )
        },
      }),
    ],
  },
  {
    name: 'Final_Opening_Date',
    label: 'Final Opening Date',
    col: 12,
    require: true,
    type: EnumFormFieldType.DatePicker,
    onChange: ({ form }) => {
      if (form.getFieldValue('Closed_Date')) {
        form.validateFields(['Closed_Date'])
      }
    },
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) return Promise.resolve('')
          const limitTime = (getFieldValue('Project_Start_Date') as Moment)
          ?.startOf('day')
          .unix()
          const currentTime = value?.startOf('day').unix()
          if (limitTime && limitTime <= currentTime) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error('Final Opening Date必须晚于Project Start Date。')
          )
        },
      }),
    ],
  },
  {
    name: 'Pop_up_Date',
    label: 'Pop-up Date',
    type: EnumFormFieldType.DateRangePicker,
    col: 12,
  },
  {
    name: 'Closed_Date',
    label: 'Closed Date',
    type: EnumFormFieldType.DatePicker,
    col: 12,
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) return Promise.resolve('')
          const limitTime = (getFieldValue('Final_Opening_Date') as Moment)
          ?.startOf('day')
          .unix()
          const currentTime = value?.startOf('day').unix()
          if (limitTime && limitTime <= currentTime) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error('Closed Date必须晚于Final Opening Date。')
          )
        },
      }),
    ],
  },
  {
    name: 'Reason_For_Closing',
    label: 'Reason For Closing',
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Is_Change_To_Wholesale',
    label: 'Is Change To Wholesale',
    type: EnumFormFieldType.Radio,
    col: 12,
    extra: {
      options: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    },
  },
  {
    name: 'Change_To_Wholesale_Date',
    label: 'Change To Wholesale Date',
    type: EnumFormFieldType.DatePicker,
    col: 12,
    onChange: ({ form }) => {
      if (form.getFieldValue('Closed_Date')) {
        form.validateFields(['Closed_Date'])
      }
    },
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value) return Promise.resolve('')
          const limitTime = (getFieldValue('Closed_Date') as Moment)
          ?.startOf('day')
          .unix()
          const currentTime = value?.startOf('day').unix()
          if (limitTime && limitTime <= currentTime) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error('Change To Wholesale Date必须晚于Closed Date。')
          )
        },
      }),
    ],
  },
  {
    name: 'Region',
    label: 'Region',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    placeholder: '',
    onChange: ({ value, form, optionsMap }) => {
      const RMTypeId =
        optionsMap.employeeType?.find(
          (item) => item.Value == EnumRolePostion.RM
        )?.Id ?? 0
      const BDTypeId =
        optionsMap.employeeType?.find(
          (item) => item.Value == EnumRolePostion.BD
        )?.Id ?? 0
      const RMId =
        optionsMap.employeeList?.find(
          (item) => item.Position == RMTypeId && item.Region == value
        )?.Employee_No ?? null
      const BDId =
        optionsMap.employeeList?.find(
          (item) => item.Position == BDTypeId && item.Region == value
        )?.Employee_No ?? null

      form.setFields([
        { name: 'RM', value: RMId },
        { name: 'BD', value: BDId },
      ])
    },
  },
  {
    name: 'RM',
    label: EnumRolePostion.RM,
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'employeeList',
    extra: {
      disabled: true,
    },
  },
  {
    name: 'BD',
    label: EnumRolePostion.BD,
    require: false,
    type: EnumFormFieldType.Select,
    optionsName: 'employeeList',
    extra: {
      disabled: true,
    },
  },
  {
    name: 'CM',
    label: 'CM',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'CMList',
  },
  {
    name: 'SSS',
    label: 'SSS',
    type: EnumFormFieldType.Select,
    optionsName: 'SSSList',
  },
  {
    name: 'Store_Set_up_Target',
    label: 'Store Set-up Target',
    require: true,
    type: EnumFormFieldType.Input,
    getValueFromEvent: (event, form) => {
      return event.target.value.replace(/[^0-9]+/g, '')
    },
  },
]

export const HRFields: IFormField[] = [
  {
    name: 'HeadCount',
    label: 'HeadCount',
    type: EnumFormFieldType.Input,
    rules: [
      {
        pattern: /^[0-9]+$/,
        message: '只能输入数字或字母',
      },
    ],
    getValueFromEvent: (event) => {
      return event.target.value.replace(/[^0-9]+/g, '')
    },
  },
  {
    name: 'Store_MGR',
    label: 'Store MGR',
    col: 12,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Store_MGR_Contact',
    label: 'Store MGR Contact',
    col: 12,
    type: EnumFormFieldType.Input,
  },
]

export const ContractFields: IFormField[] = [
  {
    name: 'Business_Model',
    label: '营业模式',
    require: true,
    type: EnumFormFieldType.Radio,
    col: 12,
    optionsName: 'BusinessModelOptions',
  },
  {
    name: 'Cashier_Method',
    label: '收银方式',
    require: true,
    type: EnumFormFieldType.Radio,
    col: 12,
    optionsName: 'CashierMethodOptions',
  },
  {
    name: 'VIP_Discounts_In_Malls',
    label: '商场VIP折扣',
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Contract_Date',
    label: 'Contract Date',
    type: EnumFormFieldType.DateRangePicker,
    rules: [
      {
        validator(_, value) {
          if (!value) return Promise.resolve('')
          if (value?.[0] !== value?.[1]) {
            return Promise.resolve()
          }
          return Promise.reject(new Error('请输入正确的日期'))
        },
      },
    ],
  },
]

export const ITSupportFields: IFormField[] = [
  {
    name: 'Store_Email',
    label: 'Store Email',
    type: EnumFormFieldType.Input,
    rules: [
      {
        pattern:
          /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
        message: '请输入正确格式的邮箱。',
      },
    ],
  },
  {
    name: 'Network',
    label: '能否宽带',
    type: EnumFormFieldType.Select,
    optionsName: 'NetworkOptions',
  },
  {
    name: 'Need_POS_Machine',
    label: '需要提供POS机',
    type: EnumFormFieldType.Radio,
    extra: {
      options: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    },
    onChange: ({ value, form, optionsMap }) => {
      const isNeed = (value as RadioChangeEvent).target.value
      if (Number(isNeed)) {
        const POS_Label = '快钱POS'
        const POS_Id = optionsMap['POSMachineTypeOptions']?.find(
          (item) => item.label === POS_Label
        )?.value
        form.setFields([{ name: 'POS_Machine_Type', value: POS_Id }])
      }
    },
  },
  {
    name: 'Need_Shopping_Ticket_Machine',
    label: '需要提供购物小票机',
    type: EnumFormFieldType.Radio,
    extra: {
      options: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    },
  },
  {
    name: 'POS_Machine_Type',
    label: 'POS机种类',
    type: EnumFormFieldType.Radio,
    optionsName: 'POSMachineTypeOptions',
  },
]

export const BCFiedls: IFormField[] = [
  {
    name: 'BC_NAV_Code',
    label: 'BC NAV Code',
    require: true,
    col: 12,
    type: EnumFormFieldType.Input,
    rules: [
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '只能输入数字或字母',
      },
    ],
    getValueFromEvent: (event) => {
      return event.target.value.replace(/[^0-9a-zA-Z]+/g, '')
    },
  },
  {
    name: 'Original_NAV_Code',
    label: 'Original NAV Code',
    col: 12,
    type: EnumFormFieldType.Input,
  },
  {
    name: 'Longitude',
    label: 'Longitude',
    require: true,
    col: 12,
    type: EnumFormFieldType.Input,
    placeholder: '编辑店铺地址自动获取。',
    extra: {
      disabled: true,
    },
  },
  {
    name: 'Latitude',
    label: 'Latitude',
    require: true,
    col: 12,
    type: EnumFormFieldType.Input,
    placeholder: '编辑店铺地址自动获取。',
    extra: {
      disabled: true,
    },
  },
  {
    name: 'Store_Tier',
    label: '店铺级别',
    require: true,
    col: 12,
    type: EnumFormFieldType.Select,
    optionsName: 'StoreTierOptions',
    extra: {
      config_name: EnumConfigType.Store_Tier,
    },
  },
  {
    name: 'Is_High_ATV_Store',
    label: '是否为高客单门店',
    col: 12,
    type: EnumFormFieldType.Radio,
    require: true,
    extra: {
      options: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    },
  },
  {
    name: 'Is_Headquarters_Audited',
    label: '是否经过总部审批',
    col: 12,
    type: EnumFormFieldType.Radio,
    extra: {
      options: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    },
  },
  {
    name: 'Is_Relocation_Store',
    label: '是否移位后新开店',
    col: 12,
    type: EnumFormFieldType.Radio,
    extra: {
      options: [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
    },
  },
]
