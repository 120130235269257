import React, { useEffect, useMemo, useState } from 'react'
import {
  BCFiedls,
  ITSupportFields,
  ContractFields,
  HRFields,
  StoreInfoFields,
} from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import apiCollection from '../../api'
import {
  EnumConfigType,
  EnumFormFieldType,
  EnumOperateType,
} from '../../types/enums'
import { IAreaItem } from '../../api/apiModel/publicApi'
import { ICityTierItem, IOwnStoreItem } from '../../api/apiModel/ownStore'
import { IFromMapItem } from '../../types/interface'
import { message } from 'antd'
import { FormChangeInfo } from 'rc-field-form/lib/FormContext'

interface OwnStoreDetailProps {
  isEdit?: boolean
  editData?: IOwnStoreItem
  cityTierList?: ICityTierItem[]
  operateCallback: (type: EnumOperateType) => void
}

enum EnumFormName {
  StoreInfo = 'Store Info(Own Store)',
  HR = 'HR',
  Contract = 'Contract',
  IT_Support = 'IT Support',
  BC = 'BC',
}

interface IAreaOptions extends IAreaItem {
  label: string
  value: number
  children?: IAreaOptions[]
}

const OwnStoreDetailContent = ({
  isEdit,
  editData,
  cityTierList = [],
  operateCallback,
}: OwnStoreDetailProps) => {
  const { configMap, areaList, employeeList } = useStoreViewContext()

  const [initData, setInitData] = useState(null)

  const optionsMap = useMemo(() => {
    let areaOptions: IAreaOptions[] = []
    let countryList = []
    if (configMap.size && !!areaList?.length) {
      const areaLevelTypeList = configMap.get(EnumConfigType.Area_Level)
      const provinceTypeId = areaLevelTypeList.find(
        (tItem) => tItem.Value == '省'
      )?.Id
      areaOptions = areaList?.reduce(
        (preList, item) =>
          item.Level == provinceTypeId
            ? preList.concat({ ...item, label: item.Name_CN, value: item.Id })
            : preList,
        []
      )
      areaOptions?.map((item) => {
        item.children = areaList?.reduce((preList, areaItem) => {
          let cityInfo: Partial<IAreaOptions>
          if (areaItem.Parent_Id == item.Id) {
            cityInfo = {
              ...areaItem,
              label: areaItem.Name_CN,
              value: areaItem.Id,
            }
            cityInfo.children = areaList.reduce((cityChildren, dItem) => {
              return dItem.Parent_Id === areaItem.Id
                ? cityChildren.concat({
                    ...dItem,
                    label: dItem.Name_CN,
                    value: dItem.Id,
                  })
                : cityChildren
            }, [])
          }
          return cityInfo?.Id ? preList.concat(cityInfo) : preList
        }, [])
      })
      const contryTypeId = areaLevelTypeList.find(
        (tItem) => tItem.Value == '国家'
      )?.Id
      countryList = areaList?.reduce(
        (preList, item) =>
          item.Level == contryTypeId
            ? preList.concat({ ...item, Value: item.Name_CN })
            : preList,
        []
      )
    }

    const POSMachineTypeOptions = configMap
      .get(EnumConfigType.POS_Machine_Type)
      ?.map((item) => ({ label: item.Value, value: item.Id }))

    const NetworkOptions = configMap.get(EnumConfigType.Network)

    const BusinessModelOptions = configMap
      .get(EnumConfigType.Business_Model)
      ?.map((item) => ({ label: item.Value, value: item.Id }))

    const CashierMethodOptions = configMap
      .get(EnumConfigType.Cashier_Method)
      ?.map((item) => ({ label: item.Value, value: item.Id }))

    const CMTypeId = configMap
      .get(EnumConfigType.Position)
      ?.find((item) => item.Value == 'CM')?.Id
    const CMList = employeeList.filter((item) => item.Position == CMTypeId)

    const SSSTypeId = configMap
      .get(EnumConfigType.Position)
      ?.find((item) => item.Value == 'SSS')?.Id
    const SSSList = employeeList.filter((item) => item.Position == SSSTypeId)

    return {
      employeeType: configMap.get(EnumConfigType.Position),
      employeeList,
      regionList: configMap.get(EnumConfigType.Store_Region),
      storeType: configMap.get(EnumConfigType.Store_Type),
      storeStatus: configMap.get(EnumConfigType.Store_Status),
      StoreTierOptions: configMap.get(EnumConfigType.Store_Tier),
      NetworkOptions,
      POSMachineTypeOptions,
      BusinessModelOptions,
      CashierMethodOptions,
      countryList,
      areaOptions,
      cityLevelList: configMap.get(EnumConfigType.City_Tier),
      cityTierList,
      SSSList,
      CMList,
    }
  }, [employeeList, configMap, areaList, cityTierList])

  const formMap = useMemo((): IFromMapItem[] => {
    const storeFields =
      StoreInfoFields?.map((item) =>
        ['Store_Code'].includes(item.name)
          ? { ...item, extra: { ...item.extra, disabled: isEdit } }
          : item
      ) ?? []
    return [
      {
        panelName: EnumFormName.StoreInfo,
        fieldsList: storeFields,
      },
      {
        panelName: EnumFormName.HR,
        fieldsList: HRFields,
      },
      {
        panelName: EnumFormName.Contract,
        fieldsList: ContractFields,
      },
      {
        panelName: EnumFormName.IT_Support,
        fieldsList: ITSupportFields,
      },
      {
        panelName: EnumFormName.BC,
        fieldsList: BCFiedls,
      },
    ]
  }, [BCFiedls, ITSupportFields, ContractFields, isEdit])

  const initDateTimeMs = (ms: number) => {
    if (ms && ms.toString().length == 10) {
      return ms * 1000
    }
    return ms || null
  }

  const initFormatData = () => {
    if (!editData || !cityTierList?.length || !Object.keys(editData).length) {
      const contryTypeId = configMap
        .get(EnumConfigType.Area_Level)
        ?.find((tItem) => tItem.Value == '国家')?.Id

      const StoreTierC = configMap
        .get(EnumConfigType.Store_Tier)
        ?.find((tItem) => tItem.Value == 'C')?.Id
      const countryId = areaList?.find((item) => item.Level == contryTypeId)?.Id

      setInitData({
        Is_High_ATV_Store: 0,
        OR_PR: 1,
        Country: countryId,
        Store_Tier: StoreTierC,
        Zstime_Store: 0,
      })
    } else {
      const { Province, City, District, ...defaultOwnStoreData } = editData

      const fields = [
        ...BCFiedls,
        ...ITSupportFields,
        ...ContractFields,
        ...HRFields,
        ...StoreInfoFields,
      ]
      fields.forEach((item) => {
        switch (item.type) {
          case EnumFormFieldType.TimePicker:
          case EnumFormFieldType.DatePicker:
            defaultOwnStoreData[item.name] = defaultOwnStoreData?.[item.name]
              ? defaultOwnStoreData?.[item.name] * 1000
              : null
            break
        }

        switch (item.name) {
          case 'Pop_up_Date':
            if (defaultOwnStoreData['Pop_up_End_Date']) {
              defaultOwnStoreData[item.name] = [
                initDateTimeMs(defaultOwnStoreData['Pop_up_Start_Date']),
                initDateTimeMs(defaultOwnStoreData['Pop_up_End_Date']),
              ]
            }
            break

          case 'Contract_Date':
            if (defaultOwnStoreData['Contract_Start_Date']) {
              defaultOwnStoreData[item.name] = [
                initDateTimeMs(defaultOwnStoreData['Contract_Start_Date']),
                initDateTimeMs(defaultOwnStoreData['Contract_End_Date']),
              ]
            }
            break

          case 'City_Level':
            const levelId =
              cityTierList?.find((item) => item.City_Id == City)?.Tier ?? null
            defaultOwnStoreData[item.name] = levelId
            break
        }
      })
      setInitData({
        ...defaultOwnStoreData,
        ProvinceCityDistrict: [Province, City, District],
      })
    }
  }

  const onFormChange = async (name: string, info: FormChangeInfo) => {
    const { changedFields } = info
    if (
      name === EnumFormName.StoreInfo &&
      changedFields?.[0]?.name?.[0] === 'Address'
    ) {
      const form = info.forms[EnumFormName.BC]
      form.setFields([
        { name: 'Longitude', value: '' },
        { name: 'Latitude', value: '' },
      ])
      const { value: Address } = changedFields[0]
      const formatPathname =
        (typeof window !== 'undefined' &&
          window.location.pathname.replace(/(\/)?/gi, '').toUpperCase()) ||
        'OWNSTORE'

      const keyName = 'default'
      const formationFormName = `${formatPathname}_${keyName
        .replace(/\s+/gi, '_')
        .toUpperCase()}`
      const dataStr = window.localStorage.getItem(formationFormName)
      const catchData = dataStr ? JSON.parse(dataStr) : {}

      try {
        if (!Address) {
          delete catchData.Address
          throw new Error('Address is null!')
        }

        const result = await apiCollection.getGeocoding({ Address })

        if (!result.success) {
          throw new Error(result.message)
        }

        const { lat, lng } = result.data
        const Longitude = lng.toFixed(6)
        const Latitude = lat.toFixed(6)
        form.setFields([
          { name: 'Longitude', value: Longitude },
          { name: 'Latitude', value: Latitude },
        ])
        form.validateFields(['Longitude', 'Latitude'])

        window.localStorage.setItem(
          formationFormName,
          JSON.stringify({ ...catchData, ...{ Longitude, Latitude } })
        )
      } catch (error) {
        message.error('未查询到对应经纬度，请检查地址输入')
        delete catchData.Longitude
        delete catchData.Latitude

        window.localStorage.setItem(
          formationFormName,
          JSON.stringify(catchData)
        )
      }
    }
  }

  const submitCallback = async (formData): Promise<void> => {
    const { Authorization_Date, ProvinceCityDistrict, ...defaultOwnStoreData } =
      formData
    const fields = [
      ...BCFiedls,
      ...ITSupportFields,
      ...ContractFields,
      ...HRFields,
      ...StoreInfoFields,
    ]

    fields.forEach((item) => {
      switch (item.name) {
        case 'Pop_up_Date':
          defaultOwnStoreData['Pop_up_Start_Date'] =
            defaultOwnStoreData?.[item.name]?.[0] || null
          defaultOwnStoreData['Pop_up_End_Date'] =
            defaultOwnStoreData?.[item.name]?.[1] || null
          break

        case 'Contract_Date':
          defaultOwnStoreData['Contract_Start_Date'] =
            defaultOwnStoreData?.[item.name]?.[0] || null
          defaultOwnStoreData['Contract_End_Date'] =
            defaultOwnStoreData?.[item.name]?.[1] || null
          break
      }
    })

    delete defaultOwnStoreData.Contract_Date
    delete defaultOwnStoreData.Pop_up_Date
    delete defaultOwnStoreData.City_Level

    const params: IOwnStoreItem = {
      ...defaultOwnStoreData,
      Province: ProvinceCityDistrict?.[0] ?? null,
      City: ProvinceCityDistrict?.[1] ?? null,
      District: ProvinceCityDistrict?.[2] ?? null,
    }

    const result = isEdit
      ? await apiCollection.updateOwnStore(params)
      : await apiCollection.addOwnStore(params)
    if (result.success) {
      operateCallback(EnumOperateType.Submit)
    } else {
      message.error(result.message)
      throw Error(result.message)
    }
  }

  const cancelCallback = () => {
    operateCallback(EnumOperateType.Cancel)
  }

  useEffect(() => {
    initFormatData()
  }, [editData, cityTierList, areaList])

  return (
    <>
      <FormContent
        formMap={formMap}
        optionsMap={optionsMap}
        isEdit={isEdit}
        initData={initData}
        formKeyName={initData?.OR_No}
        submitCallback={submitCallback}
        cancelCallback={cancelCallback}
        onFormChange={onFormChange}
      />
    </>
  )
}

export default OwnStoreDetailContent
