import React from 'react'

import { AuthorizationProvider } from '../context/authorizationContext/AuthorizationContext'
import { StoreViewProvider } from '../context/storeViewContext/StoreViewContext'
import Header from '../component/tools/Header'
import BodyContainer from '../component/layout/BodyContainer'
import OwnStore from '../component/ownStore/OwnStore'

import { EnumMenuTypes } from '../types/enums'

const OwnStorePageContainer = () => {
  return (
    <AuthorizationProvider>
      <StoreViewProvider>
        <Header title={EnumMenuTypes.OwnStoreList} />
        <BodyContainer>
          <OwnStore />
        </BodyContainer>
      </StoreViewProvider>
    </AuthorizationProvider>
  )
}
export default OwnStorePageContainer
