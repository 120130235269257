import React from 'react'
import { IOwnStoreItem } from '../../api/apiModel/ownStore'
import OwnStoreTable from './Table'
import OwnStoreFilter from './Filter'
import { EnumOperateType } from '../../types/enums'
import { IOperateCallbackParams } from './OwnStore'
import { Spin } from 'antd'
import { IPageInfo } from '../../types/interface'

interface IOwnStoreListProps {
  list: IOwnStoreItem[]
  isLoading: boolean
  total: number
  pageInfo: IPageInfo
  filterData: object
  storeCodeList: { value: string; label?: string }[]
  operateCallback: (
    type: EnumOperateType,
    date?: IOperateCallbackParams
  ) => void
}

const OwnStoreListContent = ({
  list,
  pageInfo,
  total,
  isLoading,
  filterData,
  storeCodeList,
  operateCallback,
}: IOwnStoreListProps) => {
  const changeFilter = (data: object) => {
    const filter = {}
    for (const [key, value] of Object.entries(data)) {
      if (value) filter[key] = value
    }
    operateCallback(EnumOperateType.Filter, { filter })
  }

  return (
    <>
      <OwnStoreFilter
        filterData={filterData}
        storeCodeList={storeCodeList}
        changeFilter={changeFilter}
        addOwnStore={() => {
          operateCallback(EnumOperateType.Add)
        }}
      />
      <Spin spinning={isLoading} delay={300}>
        <OwnStoreTable
          list={list}
          total={total}
          pageInfo={pageInfo}
          onPageChange={(pageInfo) =>
            operateCallback(EnumOperateType.ChangePage, { pageInfo })
          }
          onClickByItem={(item) => {
            operateCallback(EnumOperateType.Update, { item })
          }}
        />
      </Spin>
    </>
  )
}

export default OwnStoreListContent
