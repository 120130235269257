import React, { useEffect, useState } from 'react'
import OwnStoreDetailContent from './Detail'
import OwnStoreListContent from './List'
import apiCollection from '../../api'
import { ICityTierItem, IOwnStoreItem } from '../../api/apiModel/ownStore'
import { EnumMenuTypes, EnumOperateType } from '../../types/enums'
import { message } from 'antd'
import { IPageInfo } from '../../types/interface'
import Menu from '../tools/Menu'

export interface IOperateCallbackParams {
  item?: IOwnStoreItem
  filter?: object
  pageInfo?: IPageInfo
}

const OwnStoreContent = () => {
  const [isShowDetail, displayDetailPage] = useState<boolean>(false)
  const [ownStoreList, setOwnStoreList] = useState<IOwnStoreItem[]>([])
  const [editData, setEditData] = useState<IOwnStoreItem>()
  const [filterData, setFilterData] = useState<object>({})
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 1, size: 10 })
  const [isLoading, showLoading] = useState<boolean>(false)
  const [ownStoreTotal, setOwnStoreTotal] = useState<number>(0)
  const [cityTierList, setCityTierList] = useState<ICityTierItem[]>([])
  const [storeCodeList, setStoreCodeList] = useState<{ value: string }[]>([])

  const getOwnStoreList = async () => {
    showLoading(true)
    const result = await apiCollection.getOwnStoreList({
      ...filterData,
      ...pageInfo,
    })

    if (result.success) {
      const { data: list, total } = result.data
      setOwnStoreList(list)
      setOwnStoreTotal(total)
    } else {
      message.error(result.message)
      setOwnStoreList([])
      setOwnStoreTotal(0)
    }

    showLoading(false)
  }

  const getAllStoreList = async () => {
    const stores = await apiCollection.getOwnStoreList({})
    const storeList =
      stores.data?.data?.map((item) => ({ value: item?.Store_Code })) ?? []
    setStoreCodeList(storeList)
  }

  const getCityTierList = async () => {
    const result = await apiCollection.getCityTierList()
    const list = result.success ? result.data?.data : []
    setCityTierList(list)
  }

  const operateCallback = (
    type: EnumOperateType,
    data?: IOperateCallbackParams
  ) => {
    switch (type) {
      case EnumOperateType.Update:
        displayDetailPage(true)
        setEditData(data.item)
        break

      case EnumOperateType.Add:
        displayDetailPage(true)
        break

      case EnumOperateType.Cancel:
        setEditData(null)
        displayDetailPage(false)
        break

      case EnumOperateType.Submit:
        setEditData(null)
        displayDetailPage(false)
        getOwnStoreList()
        break

      case EnumOperateType.Filter:
        setFilterData(data.filter)
        setPageInfo((current) => ({ ...current, page: 1 }))
        break

      case EnumOperateType.ChangePage:
        setPageInfo((current) => ({ ...current, ...data.pageInfo }))
        break
    }
  }

  useEffect(() => {
    getOwnStoreList()
  }, [pageInfo])

  useEffect(() => {
    getCityTierList()
    getAllStoreList()
  }, [])

  return (
    <>
      <Menu
        current={EnumMenuTypes.OwnStoreList}
        refreshPage={() => {
          operateCallback(EnumOperateType.Cancel)
        }}
      />
      {isShowDetail && (
        <OwnStoreDetailContent
          isEdit={!!editData?.OR_No}
          editData={editData}
          cityTierList={cityTierList}
          operateCallback={operateCallback}
        />
      )}
      {!isShowDetail && (
        <OwnStoreListContent
          list={ownStoreList}
          total={ownStoreTotal}
          filterData={filterData}
          isLoading={isLoading}
          pageInfo={pageInfo}
          storeCodeList={storeCodeList}
          operateCallback={operateCallback}
        />
      )}
    </>
  )
}

export default OwnStoreContent
