import React, { useMemo } from 'react'
import styled from 'styled-components'
import { filterFields } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import {
  EnumConfigType,
  EnumExcelType,
  EnumFormFieldType,
} from '../../types/enums'
import { IFromMapItem } from '../../types/interface'
import Download from './../icon/Download'
import UploadByExcel, { IUploadTemplateOptions } from '../upload/Upload'
import IconContent from './../icon/IconContent'
import { PlusOutlined } from '@ant-design/icons'

const OwnStoreFilterContainer = styled.div`
  padding-bottom: 4.8rem;
  position: relative;
`

interface IOwnStoreFilterProps {
  filterData: object
  storeCodeList: { value: string; label?: string }[]
  changeFilter: (filterData: object) => void
  addOwnStore: () => void
}

const OwnStoreFilterContent = ({
  filterData,
  storeCodeList,
  changeFilter,
  addOwnStore,
}: IOwnStoreFilterProps) => {
  const { configMap, employeeList } = useStoreViewContext()

  const initFilterData = useMemo(() => {
    const initData = { ...filterData }
    filterFields.forEach((item) => {
      switch (item.type) {
        case EnumFormFieldType.DateRangePicker:
          if (initData[item.name]?.includes('-')) {
            initData[item.name] = initData[item.name]
              .split('-')
              .map((timeItem) => timeItem * 1000)
          }
          break
      }
    })
    return initData
  }, [filterData, filterFields])

  const optionsMap = useMemo(() => {
    const CMTypeId = configMap
      .get(EnumConfigType.Position)
      ?.find((item) => item.Value == 'CM')?.Id
    const CMList = employeeList.filter((item) => item.Position == CMTypeId)

    return {
      storeStatus: configMap.get(EnumConfigType.Store_Status),
      CMList,
      storeCodeList,
      regionList: configMap.get(EnumConfigType.Store_Region),
    }
  }, [configMap, employeeList, storeCodeList])

  const templateOptions: IUploadTemplateOptions[] = [
    {
      label: '店铺模板',
      fileName: EnumExcelType.UploadStoreTier,
      templateUrl: `report/${EnumExcelType.UploadStoreTier}`,
      uploadUrl: `report/${EnumExcelType.UploadStoreTier}`,
    },
    {
      label: '员工模板',
      fileName: EnumExcelType.UploadStorePosition,
      templateUrl: `report/${EnumExcelType.UploadStorePosition}`,
      uploadUrl: `report/${EnumExcelType.UploadStorePosition}`,
    },
  ]

  const formMap: IFromMapItem[] = [
    {
      panelName: 'Store Info(OwnStore) Filter',
      fieldsList: filterFields,
      showSubmitButton: true,
      submitButtonLabel: '搜索',
      cancelButtonLabel: '重置',
      showCancelButton: true,
      buttonPosition: 'right',
      customFormButton: [
        <Download fileUrl={`report/${EnumExcelType.StoreReport}`} />,
        <IconContent
          icon={PlusOutlined}
          tips={'新增店铺'}
          clickCallback={addOwnStore}
        />,
        <UploadByExcel
          templateOptions={templateOptions}
          uploadCallback={() => {
            changeFilter(filterData)
          }}
        />,
      ],
    },
  ]

  const resetFilter = () => {
    changeFilter({})
  }

  const submitFilter = (form) => {
    const data = { ...form }

    filterFields.forEach((item) => {
      switch (item.type) {
        case EnumFormFieldType.DateRangePicker:
          data[item.name] = data[item.name]?.join('-')
          break
      }
    })

    changeFilter(data)
  }

  return (
    <OwnStoreFilterContainer>
      <FormContent
        isEdit={true}
        initData={initFilterData}
        hiddenPanel={true}
        disableCatch={true}
        formMap={formMap}
        optionsMap={optionsMap}
        expandIcon={() => ''}
        submitCallback={submitFilter}
        cancelCallback={resetFilter}
      />
    </OwnStoreFilterContainer>
  )
}

export default OwnStoreFilterContent
